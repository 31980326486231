module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[],"hoist":false,"sourceMap":false,"autoLabel":false,"labelFormat":"[local]","extractStatic":false,"outputDir":"","importedNames":{"styled":"styled","css":"css","keyframes":"keyframes","injectGlobal":"injectGlobal","merge":"merge"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1400},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Karla","Playfair Display","Lora"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155027091-2"},
    }]
